import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import { useNavigate, useParams } from "react-router-dom";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

const TITLE = "Thể lệ chương trình";
export default function PrizeRules() {
  const navigation = useNavigate();
  const { id } = useParams();
  const handleTakePhoto = () => {
    localStorage.setItem("REDIRECT", `/prize`);
    getData(1, 10, "", "");
  };
  const getData = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetListHistory(data)
      .then((res: any) => {
        navigation(`/${id}/guide-takeaphoto`);
      })
      .catch((err) => {
        if (err === "Không thể xác thực tài khoản!") {
          navigation("/login");
        }
      });
  };
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.campaignDetailsApi("1"),
  });
  console.log(data);
  return (
    <div>
      <HeaderBackground title={TITLE} buttonBack={`/${id}`} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
        <div className="containerNotify__background-list font-light-mon box-border z-20">
          <div className=" box-border z-20 mx-6">
            <div
              className="font-regular-mon"
              dangerouslySetInnerHTML={{
                __html: data?.campaign_banner_detail,
              }}
            ></div>
          </div>
          <div className="flex justify-center mb-10 box-border font-light-mon">
            <button
              className="bg-[#16A5A3] font-bold-go px-8 py-3 rounded-xl text-[16px] text-white"
              onClick={handleTakePhoto}
            >
              Tham gia ngay
            </button>
          </div>
        </div>
      </div>
      <Logo />
      <ContactPhoneZalo />
    </div>
  );
}
