import { useEffect, useState } from "react";
import TEN_LUA from "../../assets/image/CountDown/countdown.png";
import PHONE from "../../assets/image/CountDown/phone.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { ListReceipt } from "../../models/GameModel";
import ErrorBill from "../Popup/ErrorBills";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { useQuery } from "react-query";
import { setAuthorization } from "../../services/apiService/configURL";

export default function CountDown() {
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.campaignDetailsApi("1"),
  });
  const review_image = localStorage.getItem("REVIEW_IMG");
  const sale_id = JSON.parse(localStorage.getItem("SALE_ID") || "{}");
  const { id } = useParams();
  const navigation = useNavigate();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [messError, setMessError] = useState("");
  const [checkLast, setCheckLast] = useState(false);
  const [secondCount, setSecondCount] = useState(60);
  useEffect(() => {
    setAuthorization(id as string);
    if (!isError) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (count === 1) {
              console.log("hello: ", count);
              setMinutes(0);
              setSeconds(0);
              setSecondCount(0);
              clearInterval(interval);
            } else if (count === 0) {
              console.log("hello");
              setMinutes(1);
              setSeconds(0);
              setSecondCount(60);
              setCount(count + 1);
            }
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      if (
        seconds === 2 ||
        seconds === 10 ||
        seconds === 20 ||
        seconds === 30 ||
        seconds === 40 ||
        seconds === 50 ||
        seconds === 59
      ) {
        let lastCall = false;
        if (minutes === 0 && seconds === 2 && count === 1) {
          lastCall = true;
          setCheckLast(true);
        }
        const params = {
          so_ids: sale_id,
        };
        homeServices
          .RecallSubmitReceiptApi(params as any)
          .then((res: any) => {
            console.log(res);
            if (res?.qr_code_sampling_machine) {
              localStorage.setItem("LIST_GIFT", JSON.stringify(res));
              navigation(`/${id}/video-game`);

              setMinutes(0);
              setSeconds(0);
              clearInterval(interval);
            }
          })
          .catch((err) => {
            console.log(err);
            setMessError(err);
            setIsError(true);
          });
      }
      return () => {
        clearInterval(interval);
      };
    }
  });
  const renderTime = ({ remainingTime }: any) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0 && count === 1) {
      return (
        <div className="timer text-[#16A5A3] font-semibold-mon text-[20px]">
          Hết giờ ...
        </div>
      );
    }
    return (
      <div className="countdown-text text-[#16A5A3] text-[36px] font-bold-mon flex justify-center">{`${minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`}</div>
    );
  };
  const handleClose = () => {
    setIsError(false);
    navigation(`/${id}`);
  };
  const handleSeeImg = () => {
    // setIsOpneImg(true);
  };
  const handleClickGift = () => {
    navigation(`/${id}`);
  };
  return (
    <div className="bg-coundown min-h-screen relative">
      {isError ? (
        <ErrorBill
          title={`${checkLast ? "" : "Hóa đơn không hợp lệ"}`}
          isShow={isError}
          messError={messError}
          handleClose={handleClose}
          labelBtn="Đồng ý"
          imageError={review_image as string}
        />
      ) : (
        <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
          <div className="font-bold-go text-[#0672BA] text-[28px] text-center flex">
            Vui lòng đợi {count === 1 && "thêm"}
          </div>
          <div
            className={`${count === 1 ? "mt-0" : "mt-10"} flex justify-center `}
          >
            <img src={TEN_LUA} className="w-10/12" />
          </div>
          <div className="font-regular-mon text-[#424242] text-[14px] text-center px-10">
            {count === 0
              ? "Mong quý khách thông cảm, hóa đơn của bạn đang được hệ thống xử lý"
              : "Mong quý khách thông cảm, hệ thống cần thêm thời gian để đọc hóa đơn"}
          </div>
          <div className="mt-16">
            <CountdownCircleTimer
              isPlaying
              duration={secondCount}
              colors={["#16A5A3", "#16A5A3", "#16A5A3", "#bdbdbd"]}
              colorsTime={[secondCount, 6, 3, 0]}
              onComplete={() => ({ shouldRepeat: true, delay: 1 })}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          {seconds === 0 && count === 1 && minutes === 0 ? (
            <div className="flex justify-center w-full mt-10 px-5">
              <button
                className="w-1/2 rounded-2xl mr-3 leading-5 py-2 font-regular-go uppercase text-[16px]"
                style={{
                  backgroundColor: "#EC1C2D",
                  color: "#ffffff",
                }}
                onClick={() => handleSeeImg()}
              >
                Xem lại hóa đơn
              </button>
              <button
                className="w-1/2 rounded-2xl leading-5 py-5 font-regular-go uppercase text-[16px]"
                style={{
                  backgroundColor: "#16A5A3",
                  color: "#ffffff",
                }}
                onClick={() => handleClickGift()}
              >
                Trở về trang chủ
              </button>
            </div>
          ) : null}

          <div className="flex items-center mt-20">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#333333] font-light-mon ml-2">
              Liên hệ Hotline{" "}
              <span className="font-semibold-mon text-[#16A5A3]">
                <a href={`tel:${data?.hotline}`}>{data?.hotline}</a>
              </span>
            </div>
          </div>
        </div>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
