import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    // homeSlice: HomeSlice,
  },
  devTools: true,
});

type StoreType = typeof store;
export type AppDispatch = StoreType["dispatch"];
type Config = {
  dispatch: AppDispatch;
};

export type RootState = ReturnType<typeof store.getState>;

export default store;
