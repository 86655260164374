import CANCEL_POPUP from "../../assets/image/Icon/cancel-popup.png";

export default function ImageBill({
  img,
  isShow,
  setIsShow,
}: {
  img: string;
  isShow: boolean;
  setIsShow: any;
}) {
  const handleClose = () => {
    setIsShow(false);
  };
  return (
    <div>
      {isShow && (
        <div className="absolute top-0 w-full">
          <img src={img} className="absolute top-0 w-full" />
        </div>
      )}
      <div
        className="fixed left-1/2 -translate-x-1/2 bottom-5"
        onClick={handleClose}
      >
        <img src={CANCEL_POPUP} />
      </div>
    </div>
  );
}
