import "../../assets/css/HomePage.css";
import "../../assets/css/fontText.css";
import { useEffect, useState } from "react";
import {
  BACK_GLOBAL,
  locationBefore,
} from "../../services/localService/localService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { setAuthorization } from "../../services/apiService/configURL";
import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { CampaignData, HomePageModel } from "../../models/HomePage";
import { useQuery } from "react-query";
import MainNavBar from "../../components/NavBar/MainNavBar";
import ICON_DOTS from "../../assets/image/TakeAPhoto/icon-dot.png";
import ICON_DOTS_COLOR from "../../assets/image/TakeAPhoto/icon-dots-color.png";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { LoginServices } from "../../services/apiService/LoginService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemCampaign from "./ItemCampaign";
import GIFT_HOME from "../../assets/image/Icon/gift-home.png";

export interface AxiosResponse<T = any, D = any> {
  data: HomePageModel;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

export default function HomePage() {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  localStorage.setItem(BACK_GLOBAL, "");
  document.body.style.backgroundColor = "white";
  const [name, setName] = useState("");
  const [campaign, setAllCampaign] = useState<[]>();
  useEffect(() => {
    localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
    localStorage.removeItem("REVIEW_IMG");
    localStorage.removeItem("GAME_DATA_LOCAL");
    localStorage.removeItem("LUOT_QUAY");
    localStorage.removeItem("SALE_ID");
    locationBefore.set(pathname);
    localStorage.removeItem("REDIRECT");
    setAuthorization(id as string);
    LoginServices.GetInfor()
      .then((res: any) => {
        setName(res?.name);
      })
      .catch((err) => {
        console.log(err);
      });
    const params = {
      chain: "mega",
    };
    homeServices
      ?.allCampaignApi(params)
      .then((res: any) => {
        console.log(res);
        const filterData = res?.campaign_list?.filter(
          (x: any) => x.id !== 6 && x.id !== 3
        );
        console.log(filterData);
        setAllCampaign(filterData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { data } = useQuery<any>({
    queryKey: ["getCampaign"],
    queryFn: () => homeServices.campaignDetailsApi("1"),
  });
  const getData = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetListHistory(data)
      .then((res: any) => {
        navigation(`/${id}/guide-takeaphoto`);
      })
      .catch((err) => {
        if (err === "Không thể xác thực tài khoản!") {
          navigation("/login");
        }
      });
  };
  const handleTakeAPhoto = () => {
    localStorage.setItem("REDIRECT", `/guide-takeaphoto`);
    getData(1, 10, "", "");
  };
  const handleInfor = () => {
    navigation(`/${id}/infor-customer`);
  };
  const handlePrize = () => {
    navigation(`/${id}/prize`);
  };
  const CustomDot = ({ onMove, index, onClick, active }: any) => {
    return (
      <li
        className={`${
          active ? "text-red-600" : "text-black"
        } relative top-0 h-10`}
        onClick={() => onClick()}
      >
        <div className="relative">
          {active ? (
            <img src={ICON_DOTS_COLOR} className="relative" />
          ) : (
            <img src={ICON_DOTS} className="relative" />
          )}
        </div>
      </li>
    );
  };

  return (
    <div className="">
      <div className="relative top-0 w-full">
        <div className="p-5">
          {campaign && (
            <Carousel
              showDots={true}
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              autoPlay
              centerMode={false}
              className="pb-5"
              containerClass="container-with-dots"
              dotListClass="custom-dot-list-style"
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              customDot={<CustomDot />}
              swipeable={true}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 50,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              arrows={false}
            >
              {campaign?.map((res: CampaignData) => (
                <div className="relative z-0 " key={res.id}>
                  <ItemCampaign
                    channel_id={res?.id}
                    channel_image_url={res?.intro_bg}
                    channel_name={res?.name}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div
          className="absolute top-8 right-5 w-6/12 h-12"
          onClick={handleInfor}
        >
          <div className="w-full h-full bg-[#DDFFFF] rounded-full opacity-40"></div>
          <div className="absolute w-full top-0 px-1 py-1 flex justify-between">
            <div className="pl-2">
              <div className="font-light-mon text-[14px] text-[#363534]">
                Hi
              </div>
              <div className="font-semibold-mon text-[14px] text-[#16A5A3]">
                {name && name}
              </div>
            </div>
            <div className="w-10 h-10 bg-[#f0f0f0] rounded-full flex justify-center items-center">
              <img src={GIFT_HOME} className="w-6" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative -top-[1px] w-full">
        <img src={data?.background_img} />
        <button
          onClick={handleTakeAPhoto}
          className="fixed font-regular-go text-[20px] shadow-item bottom-44 uppercase rounded-xl font-bold left-1/2 w-8/12 py-4 -translate-x-1/2"
          style={{
            backgroundColor: "#ec1c2d",
            border: "3px solid #8CC3F3",
            color: "#ffffff",
          }}
        >
          tham gia ngay
        </button>
        <button
          className="fixed bottom-32 left-1/2 font-semibold-mon -translate-x-1/2"
          style={{
            color: "#333333",
          }}
          onClick={handlePrize}
        >
          Thể lệ chương trình
        </button>
        {/* <div className="absolute top-24 left-1/2 font-semibold-mon -translate-x-1/2">
          <GoogleReCaptchaProvider
            reCaptchaKey="6LdNQ5cqAAAAACNhenGzwbG3_Mail2Cp2h-umKSz"
            language="en"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: "head", // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}
          >
            <button>hello</button>
          </GoogleReCaptchaProvider>
        </div> */}
      </div>
      <MainNavBar />
      <ContactPhoneZalo />
    </div>
  );
}
