import { useLocation, useNavigate, useParams } from "react-router-dom";
import GAME_NAVBAR from "../../assets/image/NavBar/gift-navbar.png";
import ABOUTUS_NAVBAR from "../../assets/image/NavBar/aboutus-navbar.png";
import GIFT_NAVBAR from "../../assets/image/NavBar/history-navbar.png";
import HOME_NAVBAR from "../../assets/image/NavBar/home-navbar.png";
import ICON_HOME_ENABLE from "../../assets/image/Icon/home.png";
import ICON_ABOUTUS_ENABLE from "../../assets/image/Icon/aboutus.png";
import ICON_GIFT_ENABLE from "../../assets/image/Icon/gift.png";
import ICON_HISTORY_ENABLE from "../../assets/image/Icon/history.png";
import HISTORY_DISABLE from "../../assets/image/NavBar/history.png";
import GIFT_DISABLE from "../../assets/image/NavBar/gift.png";
import ABOUTUS_DISABLE from "../../assets/image/NavBar/aboutus.png";
import HOME_DISABLE from "../../assets/image/NavBar/home.png";
import Logo from "./Logo";

export default function MainNavBar() {
  const navigation = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const data = JSON.parse(localStorage.getItem("HOME_DATA_LOCAL") || "{}");
  console.log(data);
  const handleRedirect = (type: string) => {
    navigation(type);
  };
  return (
    <div className="fixed bottom-[10px] z-10 w-full">
      <div className="relative top-0 w-full">
        {(location?.pathname === `/${id}` || location?.pathname === "/") && (
          <img src={HOME_NAVBAR} className="w-full" />
        )}
        {location?.pathname === `/${id}/gifts` && (
          <img src={GAME_NAVBAR} className="w-full" />
        )}
        {location?.pathname === `/${id}/list-history` && (
          <img src={GIFT_NAVBAR} className="w-full" />
        )}
        {location?.pathname === `/${id}/aboutus` && (
          <img src={ABOUTUS_NAVBAR} className="w-full" />
        )}
        <div className="absolute -top-1/2 translate-y-1/2 w-full grid grid-cols-4">
          {location?.pathname === `/${id}` || location?.pathname === "/" ? (
            <div className="relative rounded-full left-[55%] -translate-x-[45%] -top-[50%] translate-y-1/2 bg-[#16A5a3] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_HOME_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[82%] -translate-y-1/2"
              onClick={() => handleRedirect(`/${id}`)}
            >
              <img
                src={HOME_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
          {location?.pathname === `/${id}/gifts` ? (
            <div className="relative rounded-full left-[50%] -translate-x-[46%] bg-[#16A5A3] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_GIFT_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[82%] -translate-y-1/2"
              onClick={() => handleRedirect(`/${id}/gifts`)}
            >
              <img
                src={GIFT_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
          {location?.pathname === `/${id}/list-history` ? (
            <div className="relative rounded-full -top-[50%] left-[50%] -translate-x-[53%] translate-y-1/2 bg-[#16A5a3] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_HISTORY_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[82%] -translate-y-1/2"
              onClick={() => handleRedirect(`/${id}/list-history`)}
            >
              <img
                src={HISTORY_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
          {location?.pathname === `/${id}/aboutus` ? (
            <div className="relative rounded-full -top-[50%] left-[48%] -translate-x-[57%] translate-y-1/2 bg-[#16A5a3] p-5 w-[15vw]">
              <div className="relative flex justify-center">
                <img src={ICON_ABOUTUS_ENABLE} className="w-[150%]" />
              </div>
            </div>
          ) : (
            <div
              className="relative top-[82%] -translate-y-1/2"
              onClick={() => handleRedirect(`/${id}/aboutus`)}
            >
              <img
                src={ABOUTUS_DISABLE}
                className="relative top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 absolute bottom-[16px] w-full text-center">
        <div
          style={{
            transform:
              location?.pathname === `/${id}` || location?.pathname === "/"
                ? "translateX(-50%)"
                : "translateX(-50%)",
            color:
              location?.pathname === `/${id}` || location?.pathname === "/"
                ? "#16A5a3"
                : "#8A8A8A",
          }}
          className="relative left-[50%] font-regular-go text-[#8A8A8A] text-[11px] uppercase"
          onClick={() => handleRedirect(`/${id}`)}
        >
          Trang chủ
        </div>
        <div
          style={{
            color:
              location?.pathname === `/${id}/gifts` ? "#16A5a3" : "#8A8A8A",
          }}
          className="font-regular-go text-[#8A8A8A] text-[11px] uppercase"
          onClick={() => handleRedirect(`/${id}/gifts`)}
        >
          Quà của tôi
        </div>
        <div
          style={{
            transform:
              location?.pathname === `/${id}/list-history`
                ? "translateX(-53%)"
                : "translateX(-53%)",
            color:
              location?.pathname === `/${id}/list-history`
                ? "#16A5a3"
                : "#8A8A8A",
          }}
          className="relative left-[50%] font-regular-go text-[11px] uppercase"
          onClick={() => handleRedirect(`/${id}/list-history`)}
        >
          Lịch sử
        </div>
        <div
          style={{
            transform:
              location?.pathname === `/${id}/aboutus`
                ? "translateX(-57%)"
                : "translateX(-57%)",
            color:
              location?.pathname === `/${id}/aboutus` ? "#16A5a3" : "#8A8A8A",
          }}
          className="relative left-[50%] font-regular-go text-[11px] uppercase"
          onClick={() => handleRedirect(`/${id}/aboutus`)}
        >
          Về chúng tôi
        </div>
      </div>
      <Logo />
    </div>
  );
}
