export default function TabGift({ item, index }: { item: any; index: number }) {
  console.log(item);
  return (
    <>
      <tr
        className="h-12"
        style={
          index % 2 === 0
            ? { backgroundColor: "#ffffff" }
            : { backgroundColor: "#E7E7E7" }
        }
      >
        <td className="font-light-mon w-48 text-[11px] pl-1">
          {item.gift_name}
        </td>
        <td className=" flex justify-center mt-1">
          <div className="border-table-listgift mt-1 w-14 ml-0">
            {" "}
            {item.plan}
          </div>
        </td>
        <td className="px-[3px]">
          <div
            className="border-table-listwhitegift"
            style={{ backgroundColor: "#ffffff" }}
          >
            {item?.gift_name?.toLowerCase()?.includes("300.000")
              ? parseInt(item.dropped) + 113
              : item?.gift_name?.toLowerCase()?.includes("100.000")
              ? parseInt(item.dropped) + 4
              : item?.gift_name?.toLowerCase()?.includes("50.000")
              ? parseInt(item.dropped) + 85
              : parseInt(item.dropped)}
          </div>
        </td>
        <td className="px-[3px]">
          <div
            className="border-table-listwhitegift "
            style={{ backgroundColor: "#ffffff" }}
          >
            {/* {item.remaining} */}
            {item?.gift_name?.toLowerCase()?.includes("300.000")
              ? parseInt(item.remaining) - 113
              : item?.gift_name?.toLowerCase()?.includes("100.000")
              ? parseInt(item.remaining) - 4
              : item?.gift_name?.toLowerCase()?.includes("50.000")
              ? parseInt(item.remaining) - 85
              : parseInt(item.remaining)}
          </div>
        </td>
      </tr>
    </>
  );
}
