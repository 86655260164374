import ErrorBill from "../../assets/image/Desktop/desktop.jpg";
import ZALO from "../../assets/image/Desktop/zalo.png";
import { isMobileDevice } from "../../utils/deviceModel";

export default function NoAccessBrowser() {
  let isMobile = isMobileDevice();

  const backgroundMobile = {
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "10vh",
    paddingLeft: "15px",
    paddingRight: "15px",
    backgroundColor: "#f2f5f9",
  };
  return (
    <div>
      {isMobile ? (
        <div style={backgroundMobile}>
          <div className="containe">
            <div style={{ textAlign: "center" }}>
              <div>
                <div className="font-bold-go text-[#228aff]">Thông báo</div>
                <div className="flex justify-center mt-5">
                  <img src={ZALO} className="w-6/12" />
                </div>
                <div className="noaccess-conten mt-5">
                  Bạn vui lòng truy cập ứng dụng trên trình duyệt{" "}
                  <span className="font-bold-go text-[#fc2e2e]">
                    Không phải ẩn danh
                  </span>{" "}
                  Chrome hoặc Safari của thiết bị di động. Nhấn vào dấu “…” ở
                  góc phải màn hình xong chọn “Mở bằng trình duyệt” để tiếp tục
                  tham gia chương trình.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full relative bg-desktop">
          <div className="relative w-full h-full flex justify-center items-center bg-cove min-h-screen">
            <img
              src={ErrorBill}
              className="absolute w-full top-[50%] -translate-y-1/2"
            />
          </div>
        </div>
      )}
    </div>
  );
}
