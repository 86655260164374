import { useEffect, useState } from "react";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import ParticipateHisItem from "./ParticipateHisItem";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import ImageBill from "./ImageBill";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import MainNavBar from "../../components/NavBar/MainNavBar";
import { useNavigate, useParams } from "react-router-dom";
import { setAuthorization } from "../../services/apiService/configURL";

const TITLE = "Lịch sử tham gia";
export default function ListHistory() {
  const [listHistory, setListHistory] = useState([]);
  const { id } = useParams();
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("REDIRECT", `/list-history`);
    setAuthorization(id as string);
    getData(1, 10, "", "");
  }, []);
  const getData = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetListHistory(data)
      .then((res: any) => {
        console.log(res);
        setListHistory(res?.history);
      })
      .catch((err) => {
        if (err === "Không thể xác thực tài khoản!") {
          navigation("/login");
        }
      });
  };
  const [isShow, setIsShow] = useState(false);
  const [imageBill, setImageBill] = useState("");
  const handleImage = (e: any) => {
    setIsShow(true);
    setImageBill(e);
  };
  return (
    <div>
      {isShow ? (
        <ImageBill isShow={isShow} img={imageBill} setIsShow={setIsShow} />
      ) : (
        <>
          <HeaderBackground
            title={TITLE}
            buttonBack={`/${id}/infor-customer`}
          />
          <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
            <ul className="containerNotify__background-list pt-8 box-border z-20 pb-28">
              {listHistory?.map((lstProduct: any, index) =>
                index % 2 === 0 ? (
                  <li
                    className="participatehis-item mb-3 relative left-1/2 -translate-x-1/2 w-11/12 bg-[#f0f0f0]"
                    key={index}
                    onClick={() => handleImage(lstProduct?.receipt_image)}
                  >
                    <ParticipateHisItem lstProduct={lstProduct} />
                  </li>
                ) : (
                  <li
                    className="participatehis-item mb-3 relative left-1/2 -translate-x-1/2 w-11/12 "
                    key={index}
                    onClick={() => handleImage(lstProduct?.receipt_image)}
                  >
                    <ParticipateHisItem lstProduct={lstProduct} />
                  </li>
                )
              )}
            </ul>
          </div>
        </>
      )}
      {!isShow && <MainNavBar />}

      <Logo />
      <ContactPhoneZalo />
    </div>
  );
}
