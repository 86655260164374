import { AxiosResponse } from "axios";
import { axiosInstance } from "./configURL";
import { ProfileModel } from "../../models/LoginModel";
interface CampaignData {
  points: number; // adjust this based on your actual data
  // other properties
}
export const homeServices = {
  homeApi: (): Promise<AxiosResponse<CampaignData>> => {
    return axiosInstance.get<CampaignData>("/campaign/home");
  },
  allCampaignApi: (data: any) => {
    return axiosInstance.post(
      "/sampling_machine/get_all_campaign",
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  ocrEndPointApi: (data: any) => {
    return axiosInstance.get(`/campaign/ocr_method?campaign_id=${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  submitReceiptApi: (data: any) => {
    return axiosInstance.post("/sampling_machine/submit_receipt", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  RecallSubmitReceiptApi: (data: string) => {
    return axiosInstance.post(
      "/sampling_machine/submit_receipt_countdown",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  PushReviewReceiptApi: (sale_id: string) => {
    return axiosInstance.post(
      "/campaign/submit_receipt/push_approve",
      { params: { sale_id: sale_id } },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  campaignDetailsApi: (campaign_id: string) => {
    return axiosInstance.post(
      "/sampling_machine/get_campaign_details",
      { params: { campaign_id: campaign_id } },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  customerConfirmApi: (data: any) => {
    return axiosInstance.post(
      "/campaign/consumer_confirm",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  GetNews: () => {
    return axiosInstance.get(`/campaign/news`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  GetNewsDetails: (id: string) => {
    return axiosInstance.get(`/campaign/news/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  GetProfile: () => {
    return axiosInstance.get(`/consumer/profile`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  UpdateProfile: (data: ProfileModel) => {
    return axiosInstance.post(
      `/consumer/profile/update`,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  getCheckCode: (pg_so_code: string) => {
    return axiosInstance.post("/sampling_machine/doiqua_check_code", {
      params: {
        pg_so_code: pg_so_code,
      },
    });
  },
  postExchangeGift: (data: any) => {
    return axiosInstance.post("/sampling_machine/doiqua_exchange_gift", {
      params: data,
    });
  },
};
