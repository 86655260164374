import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import "../../assets/css/ListGift.css";
import { useEffect, useState } from "react";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { PageGift } from "../../models/GiftModel";
import MainNavBar from "../../components/NavBar/MainNavBar";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import PopupPrize from "../../components/Popup/PopupPrize";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MVCTECH from "../../assets/image/Popup/mvctech.png";

export default function AboutUs() {
  const [openPopup, setOpenPopup] = useState(false);
  const handleRedirect = () => {
    window.open("https://mvc.com.vn/service/mvc-tech/", "_blank", "nooperner");
  };
  return (
    <div>
      <HeaderBackground title="Về chúng tôi" buttonBack="/" />
      <div className="containerNotify__background absolute bottom-0 h-[87%] w-full z-10">
        <ul className="containerNotify__background-listnoti relative z-0 pt-2 box-border ">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full" onClick={handleRedirect}>
              <img src={MVCTECH} />
            </div>
          </div>
          <div className="h-20"></div>
        </ul>
        <MainNavBar />
      </div>
      <PopupPrize
        isShow={openPopup}
        notify={"Hướng dẫn sử dụng"}
        content={localStorage.getItem("prize")}
        handleOk={setOpenPopup}
      />
      <ContactPhoneZalo />
    </div>
  );
}
